import {orderBy} from '@/utils'
export const mutations = {
  SET_FORM_OPTIONS: (state, data) => {
    state.formOptions = orderBy(data, 'order');
  },
  SET_POSITIONS: (state, data) => {
    state.positions = data;
  },
  SET_REGIONS: (state, data) => {
    state.regions = data;
  },
  SET_CATEGORIES: (state, data) => {
    state.categories = data;
  },
};