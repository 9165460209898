import request from '@/utils/request';

export function getFormOptions() {
  return request({
    url: '/form-options',
    method: 'get'
  });
}

export function positions(params) {
  return request({
    url: '/positions',
    method: 'get',
    params
  });
}

export function regions(params) {
  return request({
    url: '/regions',
    method: 'get',
    params
  });
}

export function categories(params) {
  return request({
    url: '/categories',
    method: 'get',
    params
  });
}