import Vue from 'vue'
import VueRouter from 'vue-router'
import middlewares from "./middlewares";
import store from "@/store/";

import admin from './modules/admin'

import {checkTokenTime} from '@/utils/auth'

Vue.use(VueRouter)

var modules = [];
modules = modules.concat(admin);

const routes = [
  {
    path: "/",
    name: "Main",
    redirect: { name: "Dashboard" },
    component: () =>
      import("@/layouts/Main"),
    children: modules
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import("@/views/auth/Login"),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if(!to.meta['savePosition']){
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.middleware) {
    let $res = checkMiddleware(to.meta.middleware);
    if (!$res || !checkTokenTime()) {
      return next({ name: "Login", query: {redirect_url: to.path} });
    }
  }
  return next();
});

function checkMiddleware(middleware) {
  var middlewareSplit = middleware.split("|");
  var result = false;
  middlewareSplit.forEach(middlewareItem => {
    if (middlewares[middlewareItem]) {
      let $res = middlewares[middlewareItem](store);
      if($res) result = true;
    }
  });
  return result;
}

export default router
