<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
export default {
  data(){
    return {
    }
  },
  created() {
    this.cacheClear();
    this.actionGetFormOptions();
  },
  computed: {},
  methods: {
    ...mapActions({
      actionGetFormOptions: 'classifiers/getFormOptions'
    }),
    cacheClear(){
      if(caches){
        caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
        console.log('cache deleted')
      }
    },
  }
};
</script>
