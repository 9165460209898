import { 
  currentUser, 
  logout, 
  login,
} from '@/api/auth';

import { setToken, setTokenTime, clearForLogout } from '@/utils/auth';

export const actions = {
  currentUser({ commit }) {
    return new Promise((resolve, reject) => {
      currentUser()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_USER', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
      .then(res => {
        if (res['success'] && res['data']) {
          clearForLogout();
        }
        resolve(true);
      })
      .catch(err => {
        reject(err);
      });
    });
  },
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      login(data)
        .then(res => {
          if (res['success'] && res['data']) {
            setToken(res['data']['access_token']);
            setTokenTime(res['data']['expires_in']);
            commit('SET_IS_AUTH', true);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
