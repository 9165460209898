export default {
  "Информация о резидентах": "Информация о резидентах",
  "2022 г.": "2022 г.",
  "Весь год": "Весь год",
  "1-квартал": "1-квартал",
  "2-квартал": "2-квартал",
  "3-квартал": "3-квартал",
  "4-квартал": "4-квартал",
  quarter_1: "1-квартал",
  quarter_2: "2-квартал",
  quarter_3: "3-квартал",
  quarter_4: "4-квартал",
  "Все регионы": "Все регионы",
  "Республика Каракалпакстан": "Республика Каракалпакстан",
  "Бухарская область": "Бухарская область",
  "Самаркандская область": "Самаркандская область",
  "Навоийская область": "Навоийская область",
  "Андижанская область": "Андижанская область",
  "Ферганская область": "Ферганская область",
  "Сурхандарьинская область": "Сурхандарьинская область",
  "Сырдарьинская область": "Сырдарьинская область",
  "Хорезмская область": "Хорезмская область",
  "Ташкентская область": "Ташкентская область",
  "Кашкадарьинская область": "Кашкадарьинская область",
  "Джизакская область": "Джизакская область",
  "Наманганская область": "Наманганская область",
  "город Ташкент": "город Ташкент",
  "Всего резидентов:": "Всего резидентов:",
  "Экспортеры:": "Экспортеры:",
  "Резиденты с участием иностранного капитала:":
    "Резиденты с участием иностранного капитала:",
  "По форме собственности:": "По форме собственности:",
  "Объём оказанных услуг:": "Объём оказанных услуг:",
  "Объём экспорта:": "Объём экспорта:",
  "Прогноз:": "Прогноз:",
  "тыс. сум": "тыс. сум",
  "Предоставлена:": "Предоставлена:",
  "Услуга “Virtual office”": "Услуга “Virtual office”",
  "Услуга “One Stop Shop”": "Услуга “One Stop Shop”",
  "Отказ в предоставлении статуса резидента":
    "Отказ в предоставлении статуса резидента",
  "Получившие статус резидента": "Получившие статус резидента",
  "Лишено статуса резидента": "Лишено статуса резидента",
  "Образовательные компании:": "Образовательные компании:",
  "Количество сотрудников:": "Количество сотрудников:",
  раза: "раза",
  Регионы: "Регионы",
  "Прочие 7 стран по": "Прочие 7 стран по",
  "г.": "г.",
  "Республика Каракалпакстан": "Республика Каракалпакстан",
  "Бухарская область": "Бухарская область",
  "Самаркандская область": "Самаркандская область",
  "Навоийская область": "Навоийская область",
  "Андижанская область": "Андижанская область",
  "Ферганская область": "Ферганская область",
  "Сурхандарьинская область": "Сурхандарьинская область",
  "Сырдарьинская область": "Сырдарьинская область",
  "Хорезмская область": "Хорезмская область",
  "Ташкентская область": "Ташкентская область",
  "Кашкадарьинская область": "Кашкадарьинская область",
  "Джизакская область": "Джизакская область",
  "Наманганская область": "Наманганская область",
  "город Ташкент": "город Ташкент",
  "Список стран экспортеров услуг резидентов IT Park":
    "Список стран экспортеров услуг резидентов IT Park",
  Россия: "Россия",
  Беларусь: "Беларусь",
  Казахстан: "Казахстан",
  США: "США",
  "Южная Корея": "Южная Корея",
  Великобритания: "Великобритания",
  Германия: "Германия",
  Нидерланды: "Нидерланды",
  ОАЭ: "ОАЭ",
  Швеция: "Швеция",
  Литва: "Литва",
  Китай: "Китай",
  Казахстан: "Казахстан",
  Эстония: "Эстония",
  Латвия: "Латвия",
  Израиль: "Израиль",
  Кипр: "Кипр",
  Новая: "Новая",
  Ирландия: "Ирландия",
  Франция: "Франция",
  Гонконг: "Гонконг",
  Канада: "Канада",
  Австрия: "Австрия",
  Италия: "Италия",
  Бельгия: "Бельгия",
  Сингапур: "Сингапур",
  Швейцария: "Швейцария",
  Венгрия: "Венгрия",
  Камбоджа: "Камбоджа",
  Грузия: "Грузия",
  Румыния: "Румыния",
  Индия: "Индия",
  Азербайджан: "Азербайджан",
  Финляндия: "Финляндия",
  Малайзия: "Малайзия",
  Таджикистан: "Таджикистан",
  Чехия: "Чехия",
  Турция: "Турция",
  "Другие страны": "Другие страны",
  OOO: "OOO",
  ИП: "ИП",
  ЧП: "ЧП",
  ГУП: "ГУП",
  СП: "СП",
  ННО: "ННО",
  НОУ: "НОУ",
  АО: "АО",
  ДП: "ДП",
  УП: "УП",
  "долл. США": "долл. США",
  "Показать все": "Показать все",
  "за year год": "за {year} год",
  developers_count: "Программисты",
  foreign_developers_count: "Иностранцы",
  teachers_count: "Учителя",
  foreign_teachers_count: "Учителя с межд. сертификатом",
  "Данные за quarter квартал year года":
    "Данные за {quarter} квартал {year} года",
  "Данные за year года": "Данные за {year} года",
  "count страны": "{count} страны",
  "Показать всех резидентов": "Показать всех резидентов",
  "Топ 20 компаний": "Топ 20 компаний",
  "Форма собственности": "Форма собственности",
  "Наименование компании": "Наименование компании",
  Регион: "Регион",
  "Страна учредитель": "Страна учредитель",
  "Дата регистрации": "Дата регистрации",
  "Направление деятельности": "Направление деятельности",
  "Количество сотрудников": "Количество сотрудников",
  Всего: "Всего",
  Программисты: "Программисты",
  Иностранцы: "Иностранцы",
  Учителя: "Учителя",
  "Учителя с межд. сертификатом": "Учителя с межд. сертификатом",
  Направление: "Направление",
  "Все направление": "Все направление",
  Год: "Год",
  Период: "Период",
  "Всего резидентов": "Всего резидентов",
  "Объем оказанных услуг": "Объем оказанных услуг",
  млрд: "млрд",
  сум: "сум",
  "Объем экспорта": "Объем экспорта",
  млн: "млн",
  USD: "USD",
  "По направлению деятельности": "По направлению деятельности",
  "По форме собственности": "По форме собственности",
  "Тип организаций": "Тип организаций",
  "за весь период": "за весь период",
  "Показать все страны-экспортеры": "Показать все страны-экспортеры",
  "Резиденты с участием иностранного капитала":
    "Резиденты с участием иностранного капитала",
  Выход: "Выход",
  "Тип компании": "Тип компании",
  "Процентное соотношение": "Процентное соотношение",
  Экспортеры: "Экспортеры",
  Все: "Все",
  gu: "ГУ",
  up: "УП",
  dp: "ДП",
  nno: "ННО",
  sp_ooo: "СП ООО",
  chp: "ЧП",
  ao: "АО",
  nou: "НОУ",
  sp: "СП",
  gup: "ГУП",
  ooo: "ООО",
  ip_ooo: "ИП ООО",
  "count компаний": "{count} компаний",
  "Статистические данные резидентов IT Park":
    "Статистические данные резидентов IT Park",
  "Войдите в личный кабинет": "Войдите в личный кабинет",
  Логин: "Логин",
  Пароль: "Пароль",
  Войти: "Войти",
  "Показать остальные регионы": "Показать остальные регионы",
  компаний: "компаний",
  "Показать динамку роста": "Показать динамку роста",
  "Показать разбивку по сотрудникам": "Показать разбивку по сотрудникам",
  "Показать кварталы": "Показать кварталы",
  "Показать меньше": "Показать меньше",
  "Перечень компаний экспортеров": "Перечень компаний экспортеров",
  "Наименование компании": "Наименование компании",
  "Прочий доход": "Прочий доход",
  "Доход от услуг": "Доход от услуг",
  foreign_employees_count: "Иностранцы",
  "Единый реестр резидентов": "Единый реестр резидентов",
  "Тип отображения": "Тип отображения",
  "поквартально": "поквартально",
  "по нарастающей": "по нарастающей"
};
